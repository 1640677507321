import { Button, ButtonProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  StyledDangerButton,
  StyledOutlineButton,
  StyledPositiveButton,
  StyledPrimaryButton,
} from './index.styles';

export interface ILoadableButtonProps extends ButtonProps {
  isLoading?: boolean;
  buttonType?: 'primary' | 'outlined' | 'danger' | 'positive';
  tooltipText?: string;
}

function getComponentByType(type: ILoadableButtonProps['buttonType']) {
  if (type === 'primary') {
    return StyledPrimaryButton;
  }

  if (type === 'outlined') {
    return StyledOutlineButton;
  }

  if (type === 'danger') {
    return StyledDangerButton;
  }

  if (type === 'positive') {
    return StyledPositiveButton;
  }

  return Button;
}

export function LoadableButton({
  isLoading,
  buttonType,
  ...props
}: ILoadableButtonProps) {
  let Component = getComponentByType(buttonType);

  return (
    <Component
      {...props}
      icon={isLoading ? <LoadingOutlined /> : props.icon}
      disabled={isLoading || props.disabled}
    />
  );
}
