import { UploadFile } from 'antd/es/upload/interface';
import { IBaseApiRequest } from '@api';
import { UserDocument } from '../../entities/Document';

interface ICreate extends IBaseApiRequest {
  files?: UploadFile[];
  source: string;
  subjectId: string;
  subjectType?: string;
}
export async function create({
  api,
  files,
  source,
  subjectId,
  subjectType = 'SupportingDocumentsRequest',
}: ICreate) {
  try {
    const formData = new FormData();
    files?.forEach((file, index) => {
      formData.append(`documents[${index}]file`, file.originFileObj as Blob);
      formData.append(`documents[${index}]display_name`, file.name);
      formData.append(`documents[${index}]description`, '');
      formData.append(`documents[${index}]kind`, 'bca');
      formData.append(`documents[${index}]source`, source);
      formData.append(`documents[${index}]subjectType`, subjectType);
      formData.append(`documents[${index}]subjectId`, subjectId);
    });

    const response = await api.post(
      import.meta.env.VITE_API_URL.replace('api/', '') + 'documents/bulk',
      formData,
    );

    return {
      success: true,
      result: response.data,
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.message,
    };
  }
}

interface IDownload extends IBaseApiRequest {
  id?: string;
}
export async function download({ api, id }: IDownload) {
  const response = (await api.get(
    import.meta.env.VITE_API_URL.replace('api/', '') + `documents/${id}`,
  )) as {
    data: UserDocument;
  };

  return response.data;
}

interface IAttachDetach extends IBaseApiRequest {
  documentId: string;
  subjectType: string;
  subjectId: string;
  type: 'attach' | 'detach';
}
export async function attachDetach({
  api,
  documentId,
  subjectType,
  subjectId,
  type,
}: IAttachDetach) {
  try {
    await api.post(
      import.meta.env.VITE_API_URL.replace('api/', '') +
        `documents/${documentId}/${type}`,
      {
        subjectType,
        subjectId,
      },
    );

    return {
      success: true,
      result: {},
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.message,
    };
  }
}
