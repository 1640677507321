import styled from '@emotion/styled';
import { Typography, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

export const FilePickerWrapper = styled.div``;

export const StyledFilePicker = styled(Upload.Dragger)`
  &&& {
    .ant-upload-list-item {
      display: grid;
      grid-template-columns: auto 1fr auto;
      border-radius: 2px;
    }

    .ant-upload-list-item .ant-upload-list-item-name {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.83;
      color: var(--color-elements-primary);
    }

    .ant-upload-list-item .ant-upload-icon .anticon {
      color: var(--color-elements-primary);
    }

    .ant-upload-list-item-error .ant-upload-list-item-name {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.83;
      color: var(--color-status-error-2);
    }

    .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
      color: var(--color-status-error-2);
    }

    .ant-upload-list-item-actions .anticon {
      color: var(--color-status-error-2);
    }

    .ant-upload-list-item-done {
      background-color: transparent;
    }

    .ant-upload-list-item-error {
      background-color: transparent;
    }

    .ant-upload-list-item-error:hover {
      background-color: var(--color-button-error);
    }

    .ant-tooltip-inner {
      display: none;
    }

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-upload-list-item-actions:hover {
      & > button {
        background-color: transparent;
      }
    }

    .ant-progress-bg {
      background-color: var(--color-elements-primary);
    }

    .ant-upload-drag {
      border: 1px solid var(--color-background-additional-3);
      border-radius: 6px !important;
    }
  }
`;

export const StyledFilePickerText = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71;
    text-align: center;
    color: var(--color-text-primary);

    & > span {
      color: var(--color-elements-primary);
    }
  }
`;

export const StyledFilePickerDescription = styled(Typography.Text)`
  &&& {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    text-align: center;
    color: var(--color-text-secondary);
  }
`;

export const StyledLabel = styled(Typography.Paragraph)`
  &&& {
    margin: 0 0 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.83;
    color: var(--color-text-primary);
  }
`;

export const StyledDragIcon = styled(InboxOutlined)`
  &&& {
    display: flex;
    justify-content: center;
    margin: 0 0 12px;
    color: var(--color-elements-primary);

    svg {
      width: 36px;
      height: 36px;
    }
  }
`;
