import styled from '@emotion/styled';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { LoadableButton } from '@components';

export let StyledLink = styled(Link)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    text-decoration: underline;
    color: var(--color-text-primary-2);
  }
`;

export let ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-column-gap: 5px;
`;

export let StyledButtonLink = styled(LoadableButton)`
  &&& {
    width: auto;
    margin: 0;
    padding: 0 !important;
    color: var(--color-elements-primary);
  }
`;

export let StyledDocumentLink = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
  text-decoration: underline;
  color: var(--color-text-primary-2);
  cursor: pointer;
`;
